<template>
	<div>
		<h2>{{ pageTitle }}</h2>
		<div>
			<Namings :page="page" @input="setPageValue"></Namings>
			<SeoData :page="page" @input="setPageValue"></SeoData>
			<Other :page="page" @input="setPageValue"></Other>
			<b-tabs content-class="mt-3" class="mt-3">
				<b-tab title="HTML | RU" active>
					<div class="editor">
						<Editor
							apiKey="gpl"
							tinymceScriptSrc="https://cdnjs.cloudflare.com/ajax/libs/tinymce/5.9.2/tinymce.min.js"
							ref="editor_ru"
							:value="page['html_ru']"
							@input="(value) => setPageValue({ field: 'html_ru', value })"
							:key="renderKey"
							:initial-value="'<div></div'"
							:init="tinyMCEOptions"
						/>
					</div>
				</b-tab>
				<b-tab title="HTML | EN">
					<div class="editor">
						<Editor
							apiKey="gpl"
							tinymceScriptSrc="https://cdnjs.cloudflare.com/ajax/libs/tinymce/5.9.2/tinymce.min.js"
							ref="editor_en"
							:key="renderKey + 1"
							:value="page['html_en']"
							@input="(value) => setPageValue({ field: 'html_en', value })"
							:init="tinyMCEOptions"
						/>
					</div>
				</b-tab>
				<b-tab title="META-tags | RU">
					<MetaTags :page="page" :locale="'ru'" @input="setPageValue"></MetaTags>
				</b-tab>
				<b-tab title="META-tags | EN">
					<MetaTags :page="page" :locale="'en'" @input="setPageValue"></MetaTags>
				</b-tab>
				<b-tab title="JavaScript | <HEAD/>">
					<prism-editor
						class="code-editor"
						:value="page.script_head"
						@input="(value) => setPageValue({ field: 'script_head', value })"
						:highlight="highlighter"
						line-numbers
					></prism-editor>
				</b-tab>
				<b-tab title="JavaScript | <BODY/>">
					<prism-editor
						class="code-editor"
						:value="page.script_body"
						@input="(value) => setPageValue({ field: 'script_body', value })"
						:highlight="highlighter"
						line-numbers
					></prism-editor>
				</b-tab>
				<b-tab title="Описание новости" v-if="isNewsItem">
					<NewsDescription
						:page="page"
						@input="setPageValue"
					></NewsDescription>
				</b-tab>
				<b-tab title="Ссылки">
					<PagesLinksPicker
						ref="links_picker"
						:page="page"
						@input="setPageValue"
					>
					</PagesLinksPicker>
				</b-tab>
				<b-tab title="URL">
					<UrlEditor class="col-lg-6 col-md-12" :page="page" @input="setPageValue"></UrlEditor>
				</b-tab>
			</b-tabs>
			<div class="page-buttons">
				<b-button
					@click="setPageOptions"
					class="mt-2 ml-auto"
					variant="primary"
					:disabled="buttonDisabled"
				>
					Сохранить
				</b-button>
				<b-button
					@click="openRemoveModal"
					v-if="isEdit"
					class="mt-2 ml-auto"
					variant="danger"
					:disabled="buttonDisabled"
				>
					Удалить страницу
				</b-button>
				<b-button
					class="mt-2"
					variant="success"
					@click="openPage"
					:disabled="!Boolean(pagePath)"
				>
					Открыть страницу
				</b-button>
			</div>
		</div>
		<b-modal ref="removePageModal" title="Удаление страницы" v-if="isEdit">
			<div class="d-block">
				<h3>Вы уверены, что хотете удалить страницу "{{ page.name_ru }}"?</h3>
			</div>
			<template #modal-footer>
				<b-button class="mt-3 mr-3" @click="hideModal">Отмена</b-button>
				<b-button class="mt-3 mr-3" variant="danger" @click="removePage"
				>Удалить
				</b-button
				>
			</template>
		</b-modal>
	</div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import Loader from "components/Common/Loader";
import {pagination, prismEditor} from "mixins";
import {
	TINY_MCE_OPTIONS,
} from "utils/constants";

import { Namings, SeoData, Other, MetaTags } from "components/Pages/Content";

const Editor = () => import("@tinymce/tinymce-vue");
const MetaTagInput = () => import("components/Common/MetaTagInput");
const NewsDescription = () => import("./NewsDescription");
const PagesLinksPicker = () => import("components/Pages/Content/Links/PagesLinksPicker");
const UrlEditor = () => import('components/Pages/Content/UrlEditor')

import TinyMce from 'utils/tinyMCE'
const tinyMCE = new TinyMce()
import config from "utils/config";
export default {
	components: {
		Editor,
		MetaTagInput,
		PagesLinksPicker,
		Loader,
		NewsDescription,
		UrlEditor,
		Namings,
		SeoData,
		Other,
		MetaTags
	},
	props: {
		page: {
			required: false,
			type: Object,
		},
		isLoading: {
			required: false,
			type: Boolean,
		},
		isEdit: {
			required: false,
			type: Boolean,
		},
	},

	computed: {
		...mapGetters([
			'pageOptions'
		]),
		pagePath () {
			return this.page.url?.ru
		},
		isNewsItem () {
			return this.$helpers.hasSpecialTag(this.page, this.$config.news_item.tag)
		},
		pageTitle() {
			return this.isEdit ? "Редактирование страницы" : "Создание страницы";
		}
	},

	data: () => {
		return {
			tinyMCEOptions: tinyMCE.options,
			options: {},
			buttonDisabled: false,
			errors: {},
			script: "",
			backdropStyle: {width: "auto", height: "auto"},
			renderKey: 0,
		};
	},

	mixins: [pagination, prismEditor],

	watch: {
		"page.id": {
			deep: true,
			handler: function () {
				this.$set(this, "renderKey", Date.now());
				this.$refs["links_picker"]?.reset();
			},
		},
	},

	mounted() {
		setTimeout(() => {
			this.$set(this, "backdropStyle", {
				width: this.$el.clientWidth + "px",
				height: this.$el.clientWidth + "px",
			});
		}, 300);
	},

	provide: {
		errors () {
			return this.errors
		}
	},

	methods: {
		...mapActions([
			"deletePage",
			"updateFiles",
			"deleteDocument",
		]),
		...mapMutations([
			"SET_PAGE_VALUE",
			"PREPEND_PAGE",
			"UPDATE_PAGES_LIST_PAGE",
			"DELETE_PAGE_FROM_LIST",
		]),
		openPage () {
			const url = `${config.app.front_url}${this.pagePath}`
			window.open(url)
		},
		async onPageUpdate(page) {
			this.resetPagination();
			this.$store.dispatch("getPageBySlugOrId", page.slug_ru || page.id);
		},

		hideModal() {
			this.$refs["removePageModal"].hide();
		},
		openRemoveModal() {
			this.$refs["removePageModal"].show();
		},
		async removePage() {
			try {
				if (this.page.banner_url) {
					await this.deleteDocument({
						bannerUrl: this.page.banner_url,
						dir: "banners",
					});
				}
				await this.deletePage(this.page.id);
				this.DELETE_PAGE_FROM_LIST(this.page.id);
				this.$notify({
					group: "messages",
					type: "success",
					title: "Успех",
					text: "Страница удалена",
				});
				await this.$router.push("/pages");
			} catch (error) {
				console.log(error);
				this.$notify({
					group: "messages",
					type: "error",
					title: "Ошибка",
					text: "Не удалось удалить страницу",
				});
				this.hideModal();
			}
		},

		setPageValue({field, value}) {
			delete this.errors[field];
			this.SET_PAGE_VALUE({field, value});
		},
		validateForm() {
			return {
				name_ru: !Boolean(this.page.name_ru?.length),
				name_en: !Boolean(this.page.name_en?.length),
			};
		},
		prependPage(data) {
			this.PREPEND_PAGE(data);
		},
		updatePage(page) {
			this.UPDATE_PAGES_LIST_PAGE(page);
		},
		validatePageData (page) {
			const pageData = {
				...page,
				id: +page.id,
				name_ru: page.name_ru,
				name_en: page.name_en,
				type: page.type,
				slug_ru: page.slug_ru,
				slug_en: page.slug_en
			}

			if (pageData.type === 'custom' && pageData.tag) {
				pageData.tag_id = null
			}
			return pageData
		},
		update(page) {
			return new Promise(async (resolve, reject) => {
				try {
					const pageData = await this.$store.dispatch("updatePage", this.validatePageData(page));
					this.updatePage(pageData);
					this.$notify({
						group: "messages",
						type: "success",
						title: "Успех",
						text: "Страница обновлена",
					});
					resolve(page)
				} catch (error) {
					reject(error)
				}
			})
		},
		create (page) {
			return new Promise(async (resolve, reject) => {
				try {
					const data = await this.$store.dispatch("createPage", page);
					this.prependPage({
						id: data.id,
						name_ru: data.name_ru,
						name_en: data.name_en,
						type: data.type,
						order: data.order,
						slug_ru: data.slug_ru,
						slug_en: data.slug_en
					});
					this.$notify({
						group: "messages",
						type: "success",
						title: "Успех",
						text: "Страница создана",
					});
					resolve(data)
				} catch (error) {
					reject(error)
				}
			})
		},
		save () {
			return new Promise(async (resolve, reject) => {
					const pageLinks =
						this.page.links?.map((link) => {
							return {
								name_ru: link.name_ru,
								name_en: link.name_en,
								id: link.id,
								link_order: link.link_order,
							};
						}) || [];
					const pageLinksFiles = this.page.links_files.map((file, index) => {
						return {
							...file,
							page_id: this.page.id,
							link_order: index
						}
					})
					const pageData = {
						...this.page,
						links: pageLinks,
						links_files: pageLinksFiles
					}
					try {
						if (this.isEdit) {
							resolve(await this.update(pageData))
						} else {
							resolve(await this.create(pageData))
						}
					} catch (error) {
						reject(error)
					}
			})
		},
		async setPageOptions() {
			this.$store.commit("SET_LOADING", true);
			try {
				this.buttonDisabled = true;
				const errors = this.validateForm();
				console.log("validation", errors);
				if (!Object.values(errors).includes(true)) {
					const page = await this.save()
					// await this.updatePageFiles();
					this.onPageUpdate(page);
					await this.$router.push(`/pages/${page.slug_ru || page.id}`);
				} else {
					this.$set(this, "errors", errors);
					console.log("validation fail");
					this.$notify({
						group: "messages",
						type: "error",
						title: "Ошибка",
						text: `Неверно заполнены поля`,
					});
				}
			} catch (error) {
				if (error.response?.status) {
					switch (error.response.status) {
						case 400: {
							this.$notify({
								group: 'messages',
								type: 'error',
								title: 'Ошибка',
								text: error.response.data.message
							})
							break
						}
					}
				}
			} finally {
				this.buttonDisabled = false;
				this.$store.commit("SET_LOADING", false);
			}
		},
		updatePageFiles() {
			return new Promise (async (resolve, reject) => {
				try {
					await this.updateFiles(this.page.links_files);
					resolve()
				} catch (error) {
					console.error(error);
					this.$notify({
						group: "messages",
						type: "error",
						title: "Ошибка",
						text: "Не удалось обновить файлы страницы",
					});
					reject(error)
				}
			})
		}
	},
};
</script>

<style lang="css" src="utils/tinyMCE/spoiler/style.css"></style>

<style lang="scss">
.input-section {
	margin-top: 20px;

	.namings {
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(600px, 1fr));
	}

	.seo {
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
	}

	.page-inputs {
		grid-gap: 20px;

		.prepend-text {
			min-width: 20px;
			font-weight: 700;
		}
	}
}

.editor {
	margin-top: 20px;
}
</style>
